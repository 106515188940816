import AdapterDateFns from "@mui/lab/AdapterDateFns"; // COMENTAR EN LOCAL
import LocalizationProvider from "@mui/lab/LocalizationProvider"; // COMENTAR EN LOCAL
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"; // COMENTAR EN PROD
// import { LocalizationProvider } from "@mui/x-date-pickers"; // COMENTAR EN PROD
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import darkTheme from "./layout/DarkTheme";
import esLocale from "date-fns/locale/es";
import { getAllinfo } from "./services/AllInfo";
import HomeIcon from "@mui/icons-material/Home";
import keycloakObject from "./utils/keycloak";
import LateralNav from "./layout/LateralNav";
import { MenuProvider } from "./context/MenuContext.js";
import RemoveIcon from "@mui/icons-material/Remove";
import { ThemeProvider } from "@emotion/react";
import { useEffect, useState } from "react";
import AreaTable from "./components/area/AreaTable";
import BancoTable from "./components/banco/BancoTable";
import Dashboard from "./components/home/dashboard";
import DepartamentoTable from "./components/departamento/DepartamentoTable";
import EstadoCivilTable from "./components/estado-civil/EstadoCivilTable";
import EtapaProcesalTable from "./components/etapa-procesal/EtapaProcesalTable";
import FeriadoTable from "./components/feriado/FeriadoTable.jsx";
import FuncionTable from "./components/funcion/FuncionTable";
import FueroSubtipoActJuzgadoTable from "./components/fuero-subtipo-act-juzgado/FueroSubtipoActJuzgadoTable.jsx";
import FueroTipoActParteTable from "./components/fuero-tipo-act-parte/FueroTipoActParteTable.jsx";
import FueroTipoElevacionTable from "./components/fuero-tipo-elevacion/FueroTipoElevacionTable.jsx";
import FueroTipoElevacionEtapaProcesalTable from "./components/fuero-tipo-elevacion-etapa-procesal/FueroTipoElevacionEtapaProcesalTable.jsx";
import FueroTipoElevacionEtapaProcesalSubtipoActJuzgadoTable from "./components/fuero-tipo-elevacion-etapa-procesal-subtipo-act-juzgado/FueroTipoElevacionEtapaProcesalSubtipoActJuzgadoTable.jsx";
import FueroTipoIncidenteTable from "./components/fuero-tipo-incidente/FueroTipoIncidenteTable.jsx";
import FueroTipoIncidenteEtapaProcesalTable from "./components/fuero-tipo-incidente-etapa-procesal/FueroTipoIncidenteEtapaProcesalTable";
import FueroTipoIncidenteEtapaProcesalSubtipoActJuzgadoTable from "./components/fuero-tipo-incidente-etapa-procesal-subtipo-act-juzgado/FueroTipoIncidenteEtapaProcesalSubtipoActJuzgadoTable";
import InhabilidadTable from "./components/inhabilidad/InhabilidadTable.jsx";
import LocalidadTable from "./components/localidad/LocalidadTable";
import MateriaTable from "./components/materia/MateriaTable";
import MateriaTipoVinculoTable from "./components/materia-tipo-vinculo/MateriaTipoVinculoTable.jsx";
import MonedaTable from "./components/moneda/MonedaTable";
import NacionalidadTable from "./components/nacionalidad/NacionalidadTable";
import OrganismoAreaTable from "./components/organismo-area/OrganismoAreaTable";
import OrganismoFueroTable from "./components/organismo-fuero/OrganismoFueroTable";
import OrganismoTable from "./components/organismo/OrganismoTable";
import PaisTable from "./components/pais/PaisTable";
import ProcesoEtapaProcesalSubtipoActJuzgadoTable from "./components/proceso-etapa-procesal-subtipo-act-juzgado/ProcesoEtapaProcesalSubtipoActJuzgadoTable.jsx";
import ProcesoEtapaProcesalTable from "./components/proceso-etapa-procesal/ProcesoEtapaProcesalTable";
import ProcesoSubtipoActJuzgadoTable from "./components/proceso-subtipo-act-juzgado/ProcesoSubtipoActJuzgadoTable.jsx";
import ProcesoTable from "./components/proceso/ProcesoTable.jsx";
import ProfesionTable from "./components/profesion/ProfesionTable";
import ProvinciaTable from "./components/provincia/ProvinciaTable";
import SistemaCUTable from "./components/sistema-cu/SistemaCUTable";
import SistemaOrganismoTable from "./components/sistema-organismo/SistemaOrganismoTable";
import SistemaRolCuTable from "./components/sistema-rol-cu/SistemaRolCUTable";
import SistemaRolTable from "./components/sistema-rol/SistemaRolTable";
import SistemaRolUsuarioTable from "./components/sistema-rol-usuario/SistemaRolUsuarioTable";
import SistemaTable from "./components/sistema/SistemaTable";
import SubtipoActJuzgadoTable from "./components/subtipo-act-juzgado/SubtipoActJuzgadoTable.jsx";
import TipoActJuzgadoTable from "./components/tipo-act-juzgado/TipoActJuzgadoTable.jsx";
import TipoActParteTable from "./components/tipo-act-parte/TipoActParteTable";
import TipoDocumentoTable from "./components/tipo-documento/TipoDocumentoTable";
import TipoDomicilioTable from "./components/tipo-domicilio/TipoDomicilioTable";
import TipoElevacionTable from "./components/tipo-elevacion/TipoElevacionTable.jsx";
import TipoIncidenteTable from "./components/tipo-incidente/TipoIncidenteTable.jsx";
import TipoSocietarioTable from "./components/tipo-societario/TipoSocietarioTable";
import TipoTelefonoTable from "./components/tipo-telefono/TipoTelefonoTable";
import TipoVinculoTable from "./components/tipo-vinculo/TipoVinculoTable.jsx";
import FueroTable from "./components/fuero/FueroTable.jsx";
import UsuarioLicenciaTable from "./components/usuario-licencia/UsuarioLicenciaTable";
import UsuarioOrganismoAreaFuncionTable from "./components/usuario-organismo-area-funcion/UsuarioOrganismoAreaFuncionTable";
import UsuarioTable from "./components/usuario/UsuarioTable";

import { AplicacionIcon } from "./layout/icons/Aplicacion.jsx";
import { AreaIcon } from "./layout/icons/Area.jsx";
import { BancoIcon } from "./layout/icons/Banco.jsx";
import { ProcesoIcon } from "./layout/icons/Proceso.jsx";
import { CatEtProcIcon } from "./layout/icons/CatEtProc.jsx";
import { CatEtProcSTActJdoIcon } from "./layout/icons/CatEtProcSTActJdo.jsx";
import { CatSTActJdoIcon } from "./layout/icons/CatSTActJdo.jsx";
import { CasoUsoIcon } from "./layout/icons/CasoUso.jsx";
import { CasoUsoRolIcon } from "./layout/icons/CasoUsoRol.jsx";
import { CompetenciaIcon } from "./layout/icons/Competencia.jsx";
import { DepartamentoIcon } from "./layout/icons/Departamento.jsx";
import { DependenciaIcon } from "./layout/icons/Dependencia.jsx";
import { EtapaProcesalIcon } from "./layout/icons/EtapaProcesal.jsx";
import { EstadoCivilIcon } from "./layout/icons/EstadoCivil.jsx";
import { ExpedienteIcon } from "./layout/icons/Expediente.jsx";
import { FeriadoIcon } from "./layout/icons/Feriado.jsx";
import { FueroIcon } from "./layout/icons/Fuero.jsx";
import { FueroSTipoActJuzgadoIcon } from "./layout/icons/FueroSTipoActJuzgado.jsx";
import { FueroTipoActParteIcon } from "./layout/icons/FueroTipoActParte.jsx";
import { FueroTipoElevacionIcon } from "./layout/icons/FueroTipoElevacion.jsx";
import { FueroTipoElevEtProcIcon } from "./layout/icons/FueroTipoElevEtProc.jsx";
import { FueroTipoElevEtProcStActJdoIcon } from "./layout/icons/FueroTipoElevEtProcStActJdo.jsx";
import { FueroTipoIncidenteIcon } from "./layout/icons/FueroTipoIncidente.jsx";
import { FueroTipoIncEtProcIcon } from "./layout/icons/FueroTipoIncEtProc.jsx";  
import { FueroTipoIncEtProcStActJdoIcon } from "./layout/icons/FueroTipoIncEtProcStActJdo.jsx";
import { FuncionIcon } from "./layout/icons/Funcion.jsx";
import { InhabilidadIcon } from "./layout/icons/Inhabilidad.jsx";
import { LicenciaIcon } from "./layout/icons/Licencia.jsx";
import { LocalidadIcon } from "./layout/icons/Localidad.jsx";
import { MateriaIcon } from "./layout/icons/Materia.jsx";
import { MateriaTipoVinculoIcon } from "./layout/icons/MateriaTipoVinculo.jsx";
import { MonedaIcon } from "./layout/icons/Moneda.jsx";
import { NacionalidadIcon } from "./layout/icons/Nacionalidad.jsx";
import { OrganismoAreaIcon } from "./layout/icons/OrganismoArea.jsx";
import { OrganismoIcon } from "./layout/icons/Organismo.jsx";
import { PaisIcon } from "./layout/icons/Pais.jsx";
import { ParametrosIcon } from "./layout/icons/Parametros.jsx";
import { PersonalIcon } from "./layout/icons/Personal.jsx";
import { ProfesionIcon } from "./layout/icons/Profesion.jsx";
import { ProvinciaIcon } from "./layout/icons/Provincia.jsx";
import { RolIcon } from "./layout/icons/Rol.jsx";
import { RolUsuarioIcon } from "./layout/icons/RolUsuario.jsx";
import { SistemaIcon } from "./layout/icons/Sistema.jsx";
import { SistemaOrganimoIcon } from "./layout/icons/SistemaOrganismo.jsx";
import { STipoActJuzgadoIcon } from "./layout/icons/STipoActJuzgado.jsx";
import { TipoActJuzgadoIcon } from "./layout/icons/TipoActJuzgado.jsx";
import { TipoActParteIcon } from "./layout/icons/TipoActParte.jsx";
import { TipoDocumentoIcon } from "./layout/icons/TipoDocumento.jsx";
import { TipoDomicilioIcon } from "./layout/icons/TipoDomicilio.jsx";
import { TipoElevacionIcon } from "./layout/icons/TipoElevacion.jsx";
import { TipoIncidenteIcon } from "./layout/icons/TipoIncidente.jsx";
import { TipoSocietarioIcon } from "./layout/icons/TipoSocietario.jsx";
import { TipoTelefonoIcon } from "./layout/icons/TipoTelefono.jsx";
import { TipoVinculoIcon } from "./layout/icons/TipoVinculo.jsx";
import { UOAFIcon } from "./layout/icons/UOAF.jsx";
import { UsuarioIcon } from "./layout/icons/Usuario.jsx";

function App() {
  const [verificado, setVerificado] = useState(false);
  const [area, setArea] = useState(false);
  const [banco, setBanco] = useState(false);
  const [departamento, setDepartamento] = useState(false);
  const [estadoCivil, setEstadoCivil] = useState(false);
  const [etapaProcesal, setEtapaProcesal] = useState(false);
  const [feriado, setFeriado] = useState(false);
  const [funcion, setFuncion] = useState(false);
  const [fueroSubtipoActJuzgado, setFueroSubtipoActJuzgado] = useState(false);
  const [fueroTipoActParte, setFueroTipoActParte] = useState(false);
  const [fueroTipoElevacion, setFueroTipoElevacion] = useState(false);
  const [fueroTipoElevacionEtapaProcesal, setFueroTipoElevacionEtapaProcesal] = useState(false);
  const [fueroTipoElevacionEtapaProcesalSubtipoActJuzgado, setFueroTipoElevacionEtapaProcesalSubtipoActJuzgado] = useState(false);
  const [fueroTipoIncidente, setFueroTipoIncidente] = useState(false);
  const [fueroTipoIncidenteEtapaProcesal, setFueroTipoIncidenteEtapaProcesal] = useState(false);
  const [fueroTipoIncidenteEtapaProcesalSubtipoActJuzgado, setFueroTipoIncidenteEtapaProcesalSubtipoActJuzgado] = useState(false);
  const [inhabilidad, setInhabilidad] = useState(false);
  const [localidad, setLocalidad] = useState(false);
  const [materia, setMateria] = useState(false);
  const [materiaTipoVinculo, setMateriaTipoVinculo] = useState(false);
  const [moneda, setMoneda] = useState(false);
  const [nacionalidad, setNacionalidad] = useState(false);
  const [organismo, setOrganismo] = useState(false);
  const [organismoArea, setOrganismoArea] = useState(false);
  const [organismoFuero, setOrganismoFuero] = useState(false);
  const [pais, setPais] = useState(false);
  const [proceso, setProceso] = useState(false);
  const [procesoEtapaProcesal, setProcesoEtapaProcesal] = useState(false);
  const [procesoEtapaProcesalSubtipoActJuzgado, setProcesoEtapaProcesalSubtipoActJuzgado] = useState(false);
  const [procesoSubtipoActJuzgado, setProcesoSubtipoActJuzgado] = useState(false);
  const [profesion, setProfesion] = useState(false);
  const [provincia, setProvincia] = useState(false);
  const [sistema, setSistema] = useState(false);
  const [sistemaCU, setSistemaCU] = useState(false);
  const [sistemaOrganismo, setSistemaOrganismo] = useState(false);
  const [sistemaRol, setSistemaRol] = useState(false);
  const [sistemaRolCU, setSistemaRolCU] = useState(false);
  const [subtipoActJuzgado, setSubtipoActJuzgado] = useState(false);
  const [tipoActJuzgado, setTipoActJuzgado] = useState(false);
  const [tipoActParte, setTipoActParte] = useState(false);
  const [tipoDocumento, setTipoDocumento] = useState(false);
  const [tipoDomicilio, setTipoDomicilio] = useState(false);
  const [tipoElevacion, setTipoElevacion] = useState(false);
  const [tipoIncidente, setTipoIncidente] = useState(false);
  const [tipoSocietario, setTipoSocietario] = useState(false);
  const [tipoTelefono, setTipoTelefono] = useState(false);
  const [tipoVinculo, setTipoVinculo] = useState(false);
  const [UOAF, setUOAF] = useState(false);
  const [fuero, setFuero] = useState(false);
  const [usuario, setUsuario] = useState(false);
  const [usuarioLicencia, setUsuarioLicencia] = useState(false);
  const [usuarioSistemaRol, setUsuarioSistemaRol] = useState(false);

  useEffect(() => {
    const verificar = async () => {
      await keycloakObject.initKeycloak();
      setVerificado(true);
    };
    verificar();
  }, []);

  const dependencias_section = [];

  const aplicaciones_section = [];

  const personal_section = [];

  const expedientes_section = [];

  const parametros_section = [];

  useEffect(() => {
    const permisos = async () => {
      let _username = localStorage.getItem("UserName");
      let _desc_sistema = "usher";

      const response = await getAllinfo({
        desc_sistema: _desc_sistema,
        username: _username,
      });

      if (response.ok) {
        localStorage.setItem("UserId", response.data.datos_usuario.id);
        localStorage.setItem("emailUsuario", response.data.datos_usuario.email);
        localStorage.setItem(
          "nombreUsuario",
          response.data.datos_usuario.nombre
        );
        localStorage.setItem(
          "apellidoUsuario",
          response.data.datos_usuario.apellido
        );
        localStorage.setItem(
          "fotoUsuario",
          response.data.datos_usuario.foto_perfil
        );
        localStorage.setItem("autorizado", true);

        for (let i = 0; i < response.data.lista_roles_cus.length; i++) {
          for (
            let j = 0;
            j < response.data.lista_roles_cus[i].casos_de_uso.length;
            j++
          ) {
            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-fuero"
            ) {
              setFuero(true);
              dependencias_section.push({
                page: "Fueros",
                url: "fuero",
                Icon: FueroIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-organismo"
            ) {
              setOrganismo(true);
              dependencias_section.push({
                page: "Organismos",
                url: "organismo",
                Icon: OrganismoIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-area"
            ) {
              setArea(true);
              dependencias_section.push({
                page: "Áreas",
                url: "area",
                Icon: AreaIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-organismo_area"
            ) {
              setOrganismoArea(true);
              dependencias_section.push({
                page: "Organismos - Áreas",
                url: "organismo-area",
                Icon: OrganismoAreaIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-funcion"
            ) {
              setFuncion(true);
              dependencias_section.push({
                page: "Funciones",
                url: "funcion",
                Icon: FuncionIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-sistema"
            ) {
              setSistema(true);
              aplicaciones_section.push({
                page: "Sistemas",
                url: "/sistema",
                Icon: SistemaIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-sistema_organismo"
            ) {
              setSistemaOrganismo(true);
              aplicaciones_section.push({
                page: "Sistemas - Organismos",
                url: "sistema-organismo",
                Icon: SistemaOrganimoIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-sistema_rol"
            ) {
              setSistemaRol(true);
              aplicaciones_section.push({
                page: "Roles",
                url: "sistema-rol",
                Icon: RolIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-sistema_cu"
            ) {
              setSistemaCU(true);
              aplicaciones_section.push({
                page: "Casos de Uso",
                url: "sistema-cu",
                Icon: CasoUsoIcon,
                IconOpen: RemoveIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-sistema_rol_cu"
            ) {
              setSistemaRolCU(true);
              aplicaciones_section.push({
                page: "Casos de Uso - Roles",
                url: "sistema-rol-cu",
                Icon: CasoUsoRolIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-usuario"
            ) {
              setUsuario(true);
              personal_section.push({
                page: "Usuarios",
                url: "usuario",
                Icon: UsuarioIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-usuario_licencia"
            ) {
              setUsuarioLicencia(true);
              personal_section.push({
                page: "Licencias",
                url: "usuario-licencia",
                Icon: LicenciaIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-uoaf"
            ) {
              setUOAF(true);
              personal_section.push({
                page: "Usuario-OrganismoÁrea-Función",
                url: "usuario-organismo-area-funcion",
                Icon: UOAFIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-usuario_sistema_rol"
            ) {
              setUsuarioSistemaRol(true);
              personal_section.push({
                page: "Roles - Usuarios",
                url: "sistema-rol-usuario",
                Icon: RolUsuarioIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-nacionalidad"
            ) {
              setNacionalidad(true);
              parametros_section.push({
                page: "Nacionalidades",
                url: "nacionalidad",
                Icon: NacionalidadIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-pais"
            ) {
              setPais(true);
              parametros_section.push({
                page: "Países",
                url: "pais",
                Icon: PaisIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-provincia"
            ) {
              setProvincia(true);
              parametros_section.push({
                page: "Provincias",
                url: "provincia",
                Icon: ProvinciaIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-departamento"
            ) {
              setDepartamento(true);
              parametros_section.push({
                page: "Departamentos",
                url: "departamento",
                Icon: DepartamentoIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-feriado"
            ) {
              setFeriado(true);
              parametros_section.push({
                page: "Feriados",
                url: "feriado",
                Icon: FeriadoIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-inhabilidad"
            ) {
              setInhabilidad(true);
              parametros_section.push({
                page: "Inhabilidades",
                url: "inhabilidad",
                Icon: InhabilidadIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-localidad"
            ) {
              setLocalidad(true);
              parametros_section.push({
                page: "Localidades",
                url: "localidad",
                Icon: LocalidadIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-estado_civil"
            ) {
              setEstadoCivil(true);
              parametros_section.push({
                page: "Estados Civiles",
                url: "estado-civil",
                Icon: EstadoCivilIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-tipo_documento"
            ) {
              setTipoDocumento(true);
              parametros_section.push({
                page: "Tipos Documento",
                url: "tipo-documento",
                Icon: TipoDocumentoIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-tipo_domicilio"
            ) {
              setTipoDomicilio(true);
              parametros_section.push({
                page: "Tipos Domicilio",
                url: "tipo-domicilio",
                Icon: TipoDomicilioIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-tipo_societario"
            ) {
              setTipoSocietario(true);
              parametros_section.push({
                page: "Tipos Societarios",
                url: "tipo-societario",
                Icon: TipoSocietarioIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-tipo_telefono"
            ) {
              setTipoTelefono(true);
              parametros_section.push({
                page: "Tipos Teléfono",
                url: "tipo-telefono",
                Icon: TipoTelefonoIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-profesion"
            ) {
              setProfesion(true);
              parametros_section.push({
                page: "Profesiones",
                url: "profesion",
                Icon: ProfesionIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-banco"
            ) {
              setBanco(true);
              parametros_section.push({
                page: "Bancos",
                url: "banco",
                Icon: BancoIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-organismo_fuero"
            ) {
              setOrganismoFuero(true);
              expedientes_section.push({
                page: "Competencias",
                url: "organismo-fuero",
                Icon: CompetenciaIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-proceso"
            ) {
              setProceso(true);
              expedientes_section.push({
                page: "Procesos",
                url: "proceso",
                Icon: ProcesoIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-proceso_etapa_procesal"
            ) {
              setProcesoEtapaProcesal(true);
              expedientes_section.push({
                page: "Procesos Etapas Procesales",
                url: "proceso-etapa-procesal",
                Icon: CatEtProcIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu ===
              "obtener-n-proceso_etapa_procesal_subtipo_act_juzgado"
            ) {
              setProcesoEtapaProcesalSubtipoActJuzgado(true);
              expedientes_section.push({
                page: "Procesos Etapas Procesales Subtipos Actuación Juzgados",
                url: "proceso-etapa-procesal-subtipo-act-juzgado",
                Icon: CatEtProcSTActJdoIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu ===
              "obtener-n-proceso_subtipo_act_juzgado"
            ) {
              setProcesoSubtipoActJuzgado(true);
              expedientes_section.push({
                page: "Procesos Subtipos Actuación Juzgados",
                url: "proceso-subtipo-act-juzgado",
                Icon: CatSTActJdoIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-etapa_procesal"
            ) {
              setEtapaProcesal(true);
              expedientes_section.push({
                page: "Etapas Procesales",
                url: "etapa-procesal",
                Icon: EtapaProcesalIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-fuero_subtipo_act_juzgado"
            ) {
              setFueroSubtipoActJuzgado(true);
              expedientes_section.push({
                page: "Fueros - Subtipos Act. Juzgado",
                url: "fuero-subtipo-act-juzgado",
                Icon: FueroSTipoActJuzgadoIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-fuero_tipo_act_parte"
            ) {
              setFueroTipoActParte(true);
              expedientes_section.push({
                page: "Fueros - Tipos Actuaciones Parte",
                url: "fuero-tipo-act-parte",
                Icon: FueroTipoActParteIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-fuero_tipo_elevacion"
            ) {
              setFueroTipoElevacion(true);
              expedientes_section.push({
                page: "Fueros - Tipos Elevaciones",
                url: "fuero-tipo-elevacion",
                Icon: FueroTipoElevacionIcon,
              });
            }
            
            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-fuero_tipo_elevacion_etapa_procesal"
            ) {
              setFueroTipoElevacionEtapaProcesal(true);
              expedientes_section.push({
                page: "Fueros - Tipos Elevaciones - Etapas Procesales",
                url: "fuero-tipo-elevacion-etapa-procesal",
                Icon: FueroTipoElevEtProcIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-fuero_tipo_elevacion_etapa_procesal_subtipo_act_juzgado"
            ) {
              setFueroTipoElevacionEtapaProcesalSubtipoActJuzgado(true);
              expedientes_section.push({
                page: "Fueros - Tipos Elevaciones - Etapas Procesales - Subtipos Act. Juzgado",
                url: "fuero-tipo-elevacion-etapa-procesal-subtipo-act-juzgado",
                Icon: FueroTipoElevEtProcStActJdoIcon,
              });
            } 

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-fuero_tipo_incidente"
            ) {
              setFueroTipoIncidente(true);
              expedientes_section.push({
                page: "Fueros - Tipos Incidentes",
                url: "fuero-tipo-incidente",
                Icon: FueroTipoIncidenteIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu ===
              "obtener-n-fuero_tipo_incidente_etapa_procesal"
            ) {
              setFueroTipoIncidenteEtapaProcesal(true);
              expedientes_section.push({
                page: "Fueros - Tipos Incidentes - Etapas Procesales",
                url: "fuero-tipo-incidente-etapa-procesal",
                Icon: FueroTipoIncEtProcIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu ===
              "obtener-n-fuero_tipo_incidente_etapa_procesal_subtipo_act_juzgado"
            ) {
              setFueroTipoIncidenteEtapaProcesalSubtipoActJuzgado(true);
              expedientes_section.push({
                page: "Fueros - Tipos Incidentes - Etapas Procesales - Subtipos Act. Juzgado",
                url: "fuero-tipo-incidente-etapa-procesal-subtipo-act-juzgado",
                Icon: FueroTipoIncEtProcStActJdoIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-materia"
            ) {
              setMateria(true);
              expedientes_section.push({
                page: "Materias",
                url: "materia",
                Icon: MateriaIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-materia_tipo_vinculo"
            ) {
              setMateriaTipoVinculo(true);
              expedientes_section.push({
                page: "Materias - Tipos Vínculos",
                url: "materia-tipo-vinculo",
                Icon: MateriaTipoVinculoIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-moneda"
            ) {
              setMoneda(true);
              parametros_section.push({
                page: "Monedas",
                url: "moneda",
                Icon: MonedaIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-tipo_act_juzgado"
            ) {
              setTipoActJuzgado(true);
              expedientes_section.push({
                page: "Tipos Actuaciones Juzgado",
                url: "tipo-act-juzgado",
                Icon: TipoActJuzgadoIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-tipo_elevacion"
            ) {
              setTipoElevacion(true);
              expedientes_section.push({
                page: "Tipos Elevaciones",
                url: "tipo-elevacion",
                Icon: TipoElevacionIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-tipo_incidente"
            ) {
              setTipoIncidente(true);
              expedientes_section.push({
                page: "Tipos Incidentes",
                url: "tipo-incidente",
                Icon: TipoIncidenteIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-subtipo_act_juzgado"
            ) {
              setSubtipoActJuzgado(true);
              expedientes_section.push({
                page: "Subtipos Actuaciones Juzgado",
                url: "subtipo-act-juzgado",
                Icon: STipoActJuzgadoIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-tipo_act_parte"
            ) {
              setTipoActParte(true);
              expedientes_section.push({
                page: "Tipos de Actuaciones de Parte",
                url: "tipo-act-parte",
                Icon: TipoActParteIcon,
              });
            }

            if (
              response.data.lista_roles_cus[i].casos_de_uso[j]
                .descripcion_corta_cu === "obtener-n-tipo_vinculo"
            ) {
              setTipoVinculo(true);
              expedientes_section.push({
                page: "Tipos de Vínculos",
                url: "tipo-vinculo",
                Icon: TipoVinculoIcon,
              });
            }
          }
        }

        // Ordeno los arreglos para mostrar en el menú.
        let indice = 0;
        let objetoMovido = {};

        // Sección "Personal"
        indice = personal_section.findIndex(
          (objeto) => objeto.url === "usuario"
        );
        if (indice !== -1) {
          objetoMovido = personal_section.splice(indice, 1)[0];
          personal_section.splice(0, 0, objetoMovido);
        }
        indice = personal_section.findIndex(
          (objeto) => objeto.url === "usuario-licencia"
        );
        if (indice !== -1) {
          objetoMovido = personal_section.splice(indice, 1)[0];
          personal_section.splice(3, 0, objetoMovido);
        }

        // Sección "Aplicaciones"
        indice = aplicaciones_section.findIndex(
          (objeto) => objeto.url === "sistema-rol"
        );
        if (indice !== -1) {
          objetoMovido = aplicaciones_section.splice(indice, 1)[0];
          aplicaciones_section.splice(2, 0, objetoMovido);
        }

        // Sección "Dependencias"
        indice = dependencias_section.findIndex(
          (objeto) => objeto.url === "funcion"
        );
        if (indice !== -1) {
          objetoMovido = dependencias_section.splice(indice, 1)[0];
          dependencias_section.splice(0, 0, objetoMovido);
        }

        indice = dependencias_section.findIndex(
          (objeto) => objeto.url === "fuero"
        );
        if (indice !== -1) {
          objetoMovido = dependencias_section.splice(indice, 1)[0];
          dependencias_section.splice(1, 0, objetoMovido);
        }

        // Sección "Expedientes"
        expedientes_section.sort((a, b) => a.page.localeCompare(b.page));
      } else {
        console.log("Response nok");
        localStorage.setItem("autorizado", false);
        if (localStorage.getItem("UserName") != null) {
          keycloakObject.doLogout();
        }
      }
    };

    permisos();
  }, [verificado]);

  const currentPages = [
    { page: "Inicio", url: "/", Icon: HomeIcon },
    {
      page: "Dependencias",
      Icon: DependenciaIcon,
      IconOpen: RemoveIcon,
      section: dependencias_section,
    },
    {
      page: "Aplicaciones",
      Icon: AplicacionIcon,
      IconOpen: RemoveIcon,
      section: aplicaciones_section,
    },
    {
      page: "Personal",
      Icon: PersonalIcon,
      IconOpen: RemoveIcon,
      section: personal_section,
    },
    {
      page: "Expedientes",
      Icon: ExpedienteIcon,
      IconOpen: RemoveIcon,
      section: expedientes_section,
    },
    {
      page: "Parámetros",
      Icon: ParametrosIcon,
      IconOpen: RemoveIcon,
      section: parametros_section,
    },
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
      <BrowserRouter>
        <MenuProvider>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline enableColorScheme />
            <LateralNav currentPages={currentPages} />
            <Routes>
              <Route path="/" element={<Dashboard data={darkTheme} />} />

              {area && (
                <Route path="area" element={<AreaTable data={darkTheme} />} />
              )}

              {banco && (
                <Route path="banco" element={<BancoTable data={darkTheme} />} />
              )}

              {departamento && (
                <Route
                  path="departamento"
                  element={<DepartamentoTable data={darkTheme} />}
                />
              )}

              {estadoCivil && (
                <Route
                  path="estado-civil"
                  element={<EstadoCivilTable data={darkTheme} />}
                />
              )}

              {etapaProcesal && (
                <Route
                  path="etapa-procesal"
                  element={<EtapaProcesalTable data={darkTheme} />}
                />
              )}

              {feriado && (
                <Route
                  path="feriado"
                  element={<FeriadoTable data={darkTheme} />}
                />
              )}

              {funcion && (
                <Route
                  path="funcion"
                  element={<FuncionTable data={darkTheme} />}
                />
              )}

              {fueroSubtipoActJuzgado && (
                <Route
                  path="fuero-subtipo-act-juzgado"
                  element={<FueroSubtipoActJuzgadoTable data={darkTheme} />}
                />
              )}

              {fueroTipoActParte && (
                <Route
                  path="fuero-tipo-act-parte"
                  element={<FueroTipoActParteTable data={darkTheme} />}
                />
              )}

              {fueroTipoElevacion && (
                <Route
                  path="fuero-tipo-elevacion"
                  element={<FueroTipoElevacionTable data={darkTheme} />}
                />
              )}

              {fueroTipoElevacionEtapaProcesal && (
                <Route
                  path="fuero-tipo-elevacion-etapa-procesal"
                  element={<FueroTipoElevacionEtapaProcesalTable data={darkTheme} />}
                />
              )}

              {fueroTipoElevacionEtapaProcesalSubtipoActJuzgado && (
                <Route
                  path="fuero-tipo-elevacion-etapa-procesal-subtipo-act-juzgado"
                  element={<FueroTipoElevacionEtapaProcesalSubtipoActJuzgadoTable data={darkTheme} />}
                />
              )}

              {fueroTipoIncidente && (
                <Route
                  path="fuero-tipo-incidente"
                  element={<FueroTipoIncidenteTable data={darkTheme} />}
                />
              )}

              {fueroTipoIncidenteEtapaProcesal && (
                <Route
                  path="fuero-tipo-incidente-etapa-procesal"
                  element={<FueroTipoIncidenteEtapaProcesalTable data={darkTheme} />}
                />
              )}

              {fueroTipoIncidenteEtapaProcesalSubtipoActJuzgado && (
                <Route
                  path="fuero-tipo-incidente-etapa-procesal-subtipo-act-juzgado"
                  element={<FueroTipoIncidenteEtapaProcesalSubtipoActJuzgadoTable data={darkTheme} />}
                />
              )}

              {inhabilidad && (
                <Route
                  path="inhabilidad"
                  element={<InhabilidadTable data={darkTheme} />}
                />
              )}

              {localidad && (
                <Route
                  path="localidad"
                  element={<LocalidadTable data={darkTheme} />}
                />
              )}

              {materia && (
                <Route
                  path="materia"
                  element={<MateriaTable data={darkTheme} />}
                />
              )}

              {materiaTipoVinculo && (
                <Route
                  path="materia-tipo-vinculo"
                  element={<MateriaTipoVinculoTable data={darkTheme} />}
                />
              )}

              {moneda && (
                <Route
                  path="moneda"
                  element={<MonedaTable data={darkTheme} />}
                />
              )}

              {nacionalidad && (
                <Route
                  path="nacionalidad"
                  element={<NacionalidadTable data={darkTheme} />}
                />
              )}

              {organismo && (
                <Route
                  path="organismo"
                  element={<OrganismoTable data={darkTheme} />}
                />
              )}

              {organismoArea && (
                <Route
                  path="organismo-area"
                  element={<OrganismoAreaTable data={darkTheme} />}
                />
              )}

              {organismoFuero && (
                <Route
                  path="organismo-fuero"
                  element={<OrganismoFueroTable data={darkTheme} />}
                />
              )}

              {pais && (
                <Route path="pais" element={<PaisTable data={darkTheme} />} />
              )}

              {proceso && (
                <Route
                  path="proceso"
                  element={<ProcesoTable data={darkTheme} />}
                />
              )}

              {procesoEtapaProcesal && (
                <Route
                  path="proceso-etapa-procesal"
                  element={<ProcesoEtapaProcesalTable data={darkTheme} />}
                />
              )}

              {procesoEtapaProcesalSubtipoActJuzgado && (
                <Route
                  path="proceso-etapa-procesal-subtipo-act-juzgado"
                  element={
                    <ProcesoEtapaProcesalSubtipoActJuzgadoTable
                      data={darkTheme}
                    />
                  }
                />
              )}

              {procesoSubtipoActJuzgado && (
                <Route
                  path="proceso-subtipo-act-juzgado"
                  element={<ProcesoSubtipoActJuzgadoTable data={darkTheme} />}
                />
              )}

              {profesion && (
                <Route
                  path="profesion"
                  element={<ProfesionTable data={darkTheme} />}
                />
              )}

              {provincia && (
                <Route
                  path="provincia"
                  element={<ProvinciaTable data={darkTheme} />}
                />
              )}

              {sistema && (
                <Route
                  path="sistema"
                  element={<SistemaTable data={darkTheme} />}
                />
              )}

              {sistemaCU && (
                <Route
                  path="sistema-cu"
                  element={<SistemaCUTable data={darkTheme} />}
                />
              )}

              {sistemaOrganismo && (
                <Route
                  path="sistema-organismo"
                  element={<SistemaOrganismoTable data={darkTheme} />}
                />
              )}

              {sistemaRol && (
                <Route
                  path="sistema-rol"
                  element={<SistemaRolTable data={darkTheme} />}
                />
              )}

              {sistemaRolCU && (
                <Route
                  path="sistema-rol-cu"
                  element={<SistemaRolCuTable data={darkTheme} />}
                />
              )}

              {subtipoActJuzgado && (
                <Route
                  path="subtipo-act-juzgado"
                  element={<SubtipoActJuzgadoTable data={darkTheme} />}
                />
              )}

              {tipoActJuzgado && (
                <Route
                  path="tipo-act-juzgado"
                  element={<TipoActJuzgadoTable data={darkTheme} />}
                />
              )}

              {tipoActParte && (
                <Route
                  path="tipo-act-parte"
                  element={<TipoActParteTable data={darkTheme} />}
                />
              )}

              {tipoDocumento && (
                <Route
                  path="tipo-documento"
                  element={<TipoDocumentoTable data={darkTheme} />}
                />
              )}

              {tipoDomicilio && (
                <Route
                  path="tipo-domicilio"
                  element={<TipoDomicilioTable data={darkTheme} />}
                />
              )}

              {tipoElevacion && (
                <Route
                  path="tipo-elevacion"
                  element={<TipoElevacionTable data={darkTheme} />}
                />
              )}

              {tipoIncidente && (
                <Route
                  path="tipo-incidente"
                  element={<TipoIncidenteTable data={darkTheme} />}
                />
              )}

              {tipoSocietario && (
                <Route
                  path="tipo-societario"
                  element={<TipoSocietarioTable data={darkTheme} />}
                />
              )}

              {tipoTelefono && (
                <Route
                  path="tipo-telefono"
                  element={<TipoTelefonoTable data={darkTheme} />}
                />
              )}

              {tipoVinculo && (
                <Route
                  path="tipo-vinculo"
                  element={<TipoVinculoTable data={darkTheme} />}
                />
              )}

              {fuero && <Route path="fuero" element={<FueroTable data={darkTheme} />} />}

              {UOAF && (
                <Route
                  path="usuario-organismo-area-funcion"
                  element={
                    <UsuarioOrganismoAreaFuncionTable data={darkTheme} />
                  }
                />
              )}

              {usuario && (
                <Route
                  path="usuario"
                  element={<UsuarioTable data={darkTheme} />}
                />
              )}

              {usuarioLicencia && (
                <Route
                  path="usuario-licencia"
                  element={<UsuarioLicenciaTable data={darkTheme} />}
                />
              )}

              {usuarioSistemaRol && (
                <Route
                  path="sistema-rol-usuario"
                  element={<SistemaRolUsuarioTable data={darkTheme} />}
                />
              )}
            </Routes>
          </ThemeProvider>
        </MenuProvider>
      </BrowserRouter>
    </LocalizationProvider>
  );
}

export default App;
